/*jshint esversion: 11 */

import {
    localforage, appSelector, taskId, elem
} from "../../config";
import "../../lib/LazyLoad";
import './style.css'
import Dialog from "../../component/dialog/Dialog";
import i18n from "../../i18n";

const cleanAfterReactComponent = () => {
    const styledElement = document.querySelector( 'style[data-styled]' );
    if ( styledElement ) {
        styledElement.remove();
    }
}

const fixBase64Image = ( img ) => {

    let image = img.replace( /data:image\/\w+;base64,/g, "" );

    if ( image.length < 100 ) throw new Error( "Invalid picture" );

    return image;

};
const prepareImagePayload = ( { steps = [], additionalSteps = [] } ) => {

    let imagePayload = [ ...steps, ...additionalSteps ]
        .map( ( item, key ) => {
            let isAdditional = key >= steps.length;
            return {
                geolocation: !isAdditional ? item.geolocation : null,
                comment: item.comment,
                base64: fixBase64Image( item.photo || item ),
                imageName: `${ isAdditional ? 'additional' : item.photoType } ${ Date.now() }-${ key }.jpg`,
                imageType: isAdditional ? "additionalStep" : item.photoType,
                type: isAdditional ? "additionalStep" : item.photoType,
            }
        } );

    let imageProperties = imagePayload.map( ( item ) => {
        const { base64, ...rest } = item;
        return rest;
    } );

    return { imageProperties, imagePayload };
}


/**
 * Wrapper for WeDAT plugin-cars component
 *
 * @returns {Promise<unknown>}
 * @constructor
 */
export default async function CaptureImage ( configuration ) {

    window.scrollTo( 0, 0 );

    window.onbeforeunload = function () {
        return "Are you sure you want to leave?";
    };

    return new Promise( ( resolve, reject ) => {

        localforage.getItem( taskId ).then( ( result ) => {

            const { fieldName } = configuration;

            elem.innerHTML = "<div class='loading blinking'>...Loading</div>";

            const { model } = result;

            let PluginCarsProps = {
                selector: `#${ appSelector }`,
                datECode: model?.datEuropaCode ?? null,
                ...configuration,
                model: model,

                /* Callback returning pictures */
                completeFormCallback: function ( data ) {

                    try {

                        const { imagePayload, imageProperties } = prepareImagePayload( data );

                        if ( fieldName )
                            result.model[ fieldName ] = JSON.stringify( imageProperties );

                        /* Save data in local storage ASAP */
                        localforage
                            .setItem( taskId, { ...result, images: imagePayload } )
                            .then( ( res ) => {
                                cleanAfterReactComponent();
                                resolve()
                            } );

                    } catch (e) {

                        const { title, message } = i18n( "image_corrupted" );

                        Dialog( {
                                    title: title,
                                    message: message,
                                    timeout: 4500,
                                } );
                    }


                }
            };


            /* WeDAT plugin */
            window.PLUGIN_API.init( PluginCarsProps );

        } );
    } );
}
